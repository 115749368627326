import React, { useState, useCallback } from "react";

const Operators = {
  PLUS: "plus",
  MINUS: "minus",
  MULTIPLY: "multiply",
  DIVIDE: "divide"
};

const BasicCalculator = () => {
  const [firstNumber, setFirstNumber] = useState("");
  const [secondNumber, setSecondNumber] = useState("");
  const [selectedOperator, setSelectedOperator] = useState(Operators.PLUS);
  const [result, setResult] = useState();
  const [error, setError] = useState();

  const onFirstNumberChange = (e) => {
    setError("");
    setFirstNumber(e.target.value);
  };

  const onSecondNumberChange = (e) => {
    setError("");
    setSecondNumber(e.target.value);
  };

  const onOperatorChange = (e) => setSelectedOperator(e.target.value);

  const calculateResult = useCallback(() => {
    const first = parseFloat(firstNumber);
    const second = parseFloat(secondNumber);

    if (isNaN(first) || isNaN(second)) {
      setError("Please enter valid numbers");
      return;
    }

    switch (selectedOperator) {
      case Operators.MINUS:
        setResult(first - second);
        break;
      case Operators.MULTIPLY:
        setResult(first * second);
        break;
      case Operators.DIVIDE:
        setResult(first / second);
        break;
      default:
        setResult(first + second);
    }
  }, [firstNumber, secondNumber, selectedOperator]);

  return (
    <div>
      <h2>Basic calculator</h2>
      <div>
        <input
          type="text"
          placeholder="Enter a number"
          value={firstNumber}
          onChange={onFirstNumberChange}
        />

        <select
          className="form-select-inline mx-3"
          value={selectedOperator}
          onChange={onOperatorChange}
        >
          {Object.keys(Operators).map((operator) => (
            <option key={operator} value={Operators[operator]}>
              {operator}
            </option>
          ))}
        </select>

        <input
          type="text"
          placeholder="Enter another number"
          value={secondNumber}
          onChange={onSecondNumberChange}
        />

        <button onClick={calculateResult} className="mx-3">
          Calculate
        </button>
      </div>

      <div className="my-3">
        {error && <h4 className="text-danger">{error}</h4>}
        {result && <h3>Result:&#160;&#160;&#160;{result}</h3>}
      </div>
    </div>
  );
};

export default BasicCalculator;
