import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";

const ListItem = ({ todo, removeTodo }) => (
  <li className="list-group-item d-flex justify-content-between align-items-center">
    {todo}
    <button className="btn btn-danger" onClick={removeTodo}>
      <RiDeleteBin6Line />
    </button>
  </li>
);

const TodoList = () => {
  const [todoItem, setTodoItem] = useState("");
  const [todos, setTodos] = useState([]);

  const addTodo = () => {
    setTodos([...todos, todoItem]);
    setTodoItem("");
  };

  const removeTodo = (index) => setTodos(todos.filter((_, i) => i !== index));

  return (
    <div className="l-todolist">
      <h2 className="text-center my-3">Todo List</h2>

      <div className="input-group">
        <input
          type="text"
          className="form-control"
          value={todoItem}
          onChange={(e) => setTodoItem(e.target.value)}
        />

        <div className="input-group-append">
          <button
            className="btn btn-secondary"
            onClick={addTodo}
            disabled={todoItem.trim() === ""}
          >
            <FaPlus />
          </button>
        </div>
      </div>

      {todos.length > 0 && (
        <>
          <h3 className="text-center my-3">List</h3>
          <ul className="list-group">
            {todos.map((todo, index) => (
              <ListItem
                key={`todo_${index}`}
                todo={todo}
                removeTodo={() => removeTodo(index)}
              />
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default TodoList;
