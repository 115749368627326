import { Link } from "react-router-dom";
import { Organisms } from "../constants/RouteMap";
import { FaExternalLinkAlt } from "react-icons/fa";

const Samples = () => {
  return (
    <div className="l-component-list">
      <ol>
        {Organisms.map(({ path, label, snippet }) => (
          <li key={`sample_${label}`} className="my-2">
            <Link to={path} className="nav-link">
              {label}
            </Link>
            &#160;&#160;&#160;&#160;
            {snippet && (
              <a
                href={snippet}
                target="_blank"
                className="link"
                title="Open code snippet"
                rel="noopener noreferrer"
              >
                <FaExternalLinkAlt />
              </a>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Samples;
