import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { Organisms, Pages } from "./constants/RouteMap";
import Navbar from "./molecules/Navbar";

const Container = () => {
  return (
    <div className="container">
      <div className="mt-1">
        <HashRouter>
          <Navbar />

          <div className="mt-3">
            <Routes>
              {Organisms.map((page) => (
                <Route
                  key={page.label}
                  path={page.path}
                  element={<page.component />}
                />
              ))}

              {Pages.map((page) => (
                <Route
                  key={page.label}
                  path={page.path}
                  element={<page.component />}
                />
              ))}
            </Routes>
          </div>
        </HashRouter>
      </div>
    </div>
  );
};

export default Container;
