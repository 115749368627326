import React, { useState } from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";

const VirtualKeyboard = () => {
  const [value, setValue] = useState("");

  const list = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode(65 + i)
  );

  const addSpace = () => setValue(value + " ");

  const removePrevious = () => setValue(value.slice(0, -1));

  const clearAll = () => setValue("");

  const updateValue = (e) => setValue(value + e.target.innerText);

  return (
    <div className="row">
      <div className="l-keyboard col-6">
        <input
          type="text"
          className="form-control"
          placeholder="Click to type"
          value={value}
          disabled
        />

        <div className="text-center mt-3">
          {list.map((item) => (
            <button
              key={`key_btn_${item}`}
              className="mx-3 my-2 btn btn-lg btn-secondary flex"
              onClick={updateValue}
            >
              {item}
            </button>
          ))}
          <button
            className="mx-3 my-2 btn btn-lg btn-secondary flex"
            onClick={addSpace}
          >
            Space
          </button>
          <button
            className="mx-3 my-2 btn btn-lg btn-secondary flex"
            onClick={removePrevious}
          >
            <FaLongArrowAltLeft />
          </button>
          <button
            className="mx-3 my-2 btn btn-lg btn-secondary flex"
            onClick={clearAll}
          >
            <RiDeleteBin6Line />
          </button>
        </div>
      </div>
    </div>
  );
};

export default VirtualKeyboard;
