import React from "react";
import { NavLink } from "react-router-dom";
import { Pages } from "../constants/RouteMap";

const Navbar = () => {
  const getClassName = ({ isActive }) => {
    if (isActive) {
      return "active nav-link";
    } else {
      return "nav-link";
    }
  };

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary px-3 l-nav">
      <ul className="navbar-nav">
        {Pages.map(({ path, label }) => (
          <li className="nav-item" key={`page_${label}`}>
            <NavLink to={path} className={getClassName}>
              {label}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
