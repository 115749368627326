import React, { useState } from "react";

const SimpleCalculator = () => {
  const [input, setInput] = useState("");
  const [result, setResult] = useState(null);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const calculateResult = () => {
    try {
      setResult(eval(input)); // Note: Using eval can be dangerous and is not recommended for production code.
    } catch (error) {
      setResult("Error");
    }
  };

  return (
    <div>
      <h1>Simple Calculator</h1>
      <input
        type="text"
        value={input}
        onChange={handleInputChange}
        placeholder="Enter expression"
      />

      <button onClick={calculateResult}>Calculate</button>

      {result !== null && (
        <div className="my-3">
          <h3>Result: {result}</h3>
        </div>
      )}
    </div>
  );
};

export default SimpleCalculator;
