import Home from "../pages/Home";
import Samples from "../pages/Samples";
import BasicCalculator from "../organisms/BasicCalculator";
import SimpleCalculator from "../organisms/SimpleCalculator";
import TodoList from "../organisms/TodoList";
import VirtualKeyboard from "../organisms/VirtualKeyboard";
import NumberCodes from "../organisms/NumberCodes";
import Users from "../organisms/Users";
import Sudoku from "../organisms/Sudoku";

export const Pages = [
  { path: "/", label: "Home", component: Home },
  { path: "/samples", label: "Components", component: Samples }
];

export const Organisms = [
  {
    path: "/basic-calculator",
    label: "Basic Calculator",
    component: BasicCalculator,
    snippet: "https://bitbucket.org/_vishal/workspace/snippets/7q6nLA"
  },
  {
    path: "/virtual-keyboard",
    label: "Virtual Keyboard",
    component: VirtualKeyboard,
    snippet: "https://bitbucket.org/_vishal/workspace/snippets/Lz7r7L"
  },
  {
    path: "/todo",
    label: "Simple Todo List",
    component: TodoList,
    snippet: "https://bitbucket.org/_vishal/workspace/snippets/REyLG9"
  },
  {
    path: "/users",
    label: "Users",
    component: Users,
    snippet: "https://bitbucket.org/_vishal/workspace/snippets/AzX9jB"
  },
  {
    path: "/number-codes",
    label: "Number codes",
    component: NumberCodes
  },
  {
    path: "/simple-calculator",
    label: "Simple Calculator",
    component: SimpleCalculator,
    snippet: "https://bitbucket.org/_vishal/workspace/snippets/o7ynLq"
  },
  {
    path: "/sudoku",
    label: "Sudoku",
    component: Sudoku
  }
];
