import React, { useEffect, useState } from "react";

const Users = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetch("https://jsonplaceholder.typicode.com/users")
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .catch((error) => console.error(error));
  }, []);

  return (
    <div>
      <h3>Users list</h3>

      <table className="table my-3">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            <th scope="col">Address</th>
            <th scope="col">Website</th>
            <th scope="col">Company</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id} className="small">
              <th scope="row">{user.id}</th>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>
                <small>{user.phone}</small>
              </td>
              <td>
                <div>
                  {user.address.street}&#160;&#160;{user.address.suite}
                </div>
                <small className="text-body-secondary">
                  {user.address.city}&#160;-&#160;{user.address.zipcode}
                </small>
              </td>
              <td>{user.website}</td>
              <td>
                {user.company.name}
                <div>
                  <small className="text-body-secondary">
                    {user.company.bs}
                  </small>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="my-3">
        <small>api-url : https://jsonplaceholder.typicode.com/users</small>
      </div>
    </div>
  );
};

export default Users;
