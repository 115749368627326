import React, { useMemo } from "react";
import {
  getReducedCellData,
  getCellClasses
} from "../../functions/sudoku.functions";

const SudokuCell = ({
  row,
  col,
  cellData,
  onValueChange,
  editMode,
  setEditMode
}) => {
  const reducedValues = useMemo(
    () => getReducedCellData(cellData, row, col),
    [cellData, row, col]
  );

  const value = useMemo(() => cellData[row][col] || 0, [cellData, row, col]);

  const onCellSelection = () => setEditMode([row, col]);

  const sudokuCellClasses = useMemo(
    () => getCellClasses(row, col, editMode),
    [col, editMode, row]
  );

  const getCellValues = () =>
    value === 0 ? (
      reducedValues.map((val, i) =>
        val === 0 ? (
          <React.Fragment key={`mini-cell-${i}`}></React.Fragment>
        ) : (
          <span key={`mini-cell-${i}`} className="mini-cell">
            {val}
          </span>
        )
      )
    ) : (
      <span>{value}</span>
    );

  return (
    <div className={sudokuCellClasses} onClick={onCellSelection}>
      {editMode ? (
        <select
          value={value}
          onChange={(e) => onValueChange(parseInt(e.target.value))}
        >
          {reducedValues.map((val) => (
            <option key={`option_${val}`} value={val}>
              {val === 0 ? "" : val}
            </option>
          ))}
        </select>
      ) : (
        getCellValues()
      )}
    </div>
  );
};

export default SudokuCell;
