export const getGridCells = (row, col) => {
  const startRow = Math.floor(row / 3) * 3;
  const startCol = Math.floor(col / 3) * 3;

  const cells = [];

  for (let i = startRow; i < startRow + 3; i++)
    for (let j = startCol; j < startCol + 3; j++) cells.push([i, j]);

  return cells;
};

export const getMiniGrid = () => {
  const grid = Array.from({ length: 9 }, (_, i) => i);
  const rows = Array.from({ length: 3 }, (_, i) => i);
  const cols = Array.from({ length: 3 }, (_, i) => i);

  return grid.map((g) => {
    const list = [];

    rows.forEach((row) =>
      cols.forEach((col) => {
        list.push([row + Math.floor(g / 3) * 3, col + (g % 3) * 3]);
      })
    );

    return list;
  });
};

//  finds the possible values for a cell based on row, column and grid values
export const getReducedCellData = (cellData, row, col) => {
  const values = Array.from({ length: 9 }, (_, i) => i + 1);

  const rowValues = cellData[row];
  const colValues = cellData.map((row) => row[col]);
  const gridValues = getGridCells(row, col).map(([r, c]) => cellData[r][c]);

  return [
    0,
    ...values.filter(
      (val) =>
        !rowValues.includes(val) &&
        !colValues.includes(val) &&
        !gridValues.includes(val)
    )
  ];
};

export const getCellClasses = (row, col, editMode) => {
  const classes = ["sudoku-cell"];
  if ((row + 3) % 3 === 0) classes.push("b-t");
  if ((col + 3) % 3 === 0) classes.push("b-l");
  if (row === 8) classes.push("b-b");
  if (col === 8) classes.push("b-r");
  if (editMode) classes.push("edit-mode");
  return classes.join(" ");
};

// pass a list of cells to find the unique value
export const findUniqueVals = (cellList, cellData) => {
  const gridVals = {};

  cellList.forEach(([row, col]) => {
    if (cellData[row][col]) return;

    const cellP = getReducedCellData(cellData, row, col);

    cellP.forEach((val) => {
      if (val !== 0) gridVals[`${val}`] = gridVals[`${val}`] + 1 || 1;
    });
  });

  const unique = Object.keys(gridVals).find((key) => gridVals[key] === 1);

  return unique ? parseInt(unique) : null;
};
