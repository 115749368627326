import React, { useMemo } from "react";
import SudokuCell from "../atoms/SudokuCell";
import {
  getReducedCellData,
  getMiniGrid,
  findUniqueVals
} from "../../functions/sudoku.functions";

const getDefaultCellData = () =>
  Array.from({ length: 9 }, () => Array.from({ length: 9 }, () => null));

const Sudoku = () => {
  const rows = Array.from({ length: 9 }, (_, i) => i);
  const cols = Array.from({ length: 9 }, (_, i) => i);

  const [editMode, setEditMode] = React.useState([null, null]);
  const [cellData, setCellData] = React.useState(getDefaultCellData());

  const onCellValueChange = (row, col, value) => {
    const newCellData = [...cellData];
    newCellData[row] = newCellData[row] || [];
    newCellData[row][col] = value;
    setCellData(newCellData);
    setEditMode([null, null]);
  };

  // exit edit mode
  const onSave = () => setEditMode([null, null]);

  const onSolve = () => {
    rows.forEach((row) =>
      cols.forEach((col) => {
        if (!cellData[row][col]) {
          const reducedValues = getReducedCellData(cellData, row, col);

          if (reducedValues.length === 2)
            onCellValueChange(row, col, reducedValues[1]);
        }
      })
    );
  };

  const onReset = () => setCellData(getDefaultCellData());

  const miniGridUniques = useMemo(() => {
    const miniGrid = getMiniGrid();

    return miniGrid.map((grid, i) => findUniqueVals(grid, cellData));
  }, [cellData]);

  const rowUniques = useMemo(() => {
    return rows.map((row) =>
      findUniqueVals(
        cols.map((col) => [row, col]),
        cellData
      )
    );
  }, [cellData, cols, rows]);

  const colsUniques = useMemo(() => {
    return cols.map((col) =>
      findUniqueVals(
        rows.map((row) => [row, col]),
        cellData
      )
    );
  }, [cellData, cols, rows]);

  return (
    <div className="sudoku">
      <div className="sudoku-grid my-5">
        {rows.map((row) => (
          <div key={`row-${row}`} className="sudoku-row">
            {cols.map((col) => (
              <SudokuCell
                key={`cell_${row}_${col}`}
                row={row}
                col={col}
                cellData={cellData}
                onValueChange={(value) => onCellValueChange(row, col, value)}
                editMode={editMode[0] === row && editMode[1] === col}
                setEditMode={setEditMode}
              />
            ))}
          </div>
        ))}
      </div>

      <div className="my-5">
        {editMode[0] !== null ? (
          <button className="btn btn-primary mx-3" onClick={onSave}>
            Save
          </button>
        ) : (
          <button className="btn btn-danger mx-3" onClick={onSolve}>
            Solve
          </button>
        )}
        <button className="btn btn-secondary mx-3" onClick={onReset}>
          Reset
        </button>
      </div>

      <div>Mini Grid: {JSON.stringify(miniGridUniques)}</div>
      <div>Row: {JSON.stringify(rowUniques)}</div>
      <div>Cols: {JSON.stringify(colsUniques)}</div>
    </div>
  );
};

export default Sudoku;
