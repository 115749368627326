import React, { useMemo, useState } from "react";

const NumberCodes = () => {
  const [selectedCode, setSelectedCode] = useState();
  const [selectedPage, setSelectedPage] = useState(0);

  const pages = Array.from({ length: 33 }, (_, i) => i);

  const list = useMemo(() => {
    return Array.from(
      { length: 100 },
      (_, i) => `&#${selectedPage * 100 + i};`
    );
  }, [selectedPage]);

  return (
    <div>
      <h1>Number codes</h1>

      <div className="text-center my-3">
        {list.map((code) => (
          <button
            key={`key_entity_${code}`}
            className="mx-3 my-2 btn btn-lg btn-secondary flex"
            onClick={() => setSelectedCode(code)}
            dangerouslySetInnerHTML={{ __html: code }}
          />
        ))}
      </div>

      {selectedCode && (
        <>
          <hr />

          <div className="my-3">
            <div>Entity: &#160;&#160;&#160;&#160; {selectedCode}</div>
            <div>
              Character: &#160;&#160;{" "}
              <span dangerouslySetInnerHTML={{ __html: selectedCode }} />
            </div>
          </div>
        </>
      )}

      <hr />

      <div className="my-3">
        <div className="pagination text-align-center">
          {pages.map((page) => (
            <button
              key={`key_page_${page}`}
              className={`mx-1 btn btn-sm ${
                selectedPage === page ? "btn-primary" : "btn-secondary"
              }`}
              onClick={() => setSelectedPage(page)}
            >
              {page + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NumberCodes;
